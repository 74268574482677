import React, { useEffect } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Form, useFormik, FormikProvider } from 'formik'
import styled from '@emotion/styled'
import { TextField } from '../common/TextField'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { authActions, getUsuarioLogado } from './AuthStore'
import { IStateRedux } from '../common/ReduxStore'
import aplicacaoPadrao from '../assets/marca/aplicacao_padrao.png'
import { cursosActions } from '../cursos/CursosStore'
import { Yup } from '../common/Yup'
import { Button } from '../components'

export const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const actions = bindActionCreators(authActions, dispatch)
  const token = useSelector((s: IStateRedux) => s.auth.token)
  const cursosUsuario = useSelector((s: IStateRedux) => s.cursos.cursosUsuario)

  const {
    Layout,
    Container,
    ImgMarca,
    EsqueceuSenha,
    LembrarInformacoes,
    NaoPossuiConta,
    TermosUso,
    ContainerBotao,
    Titulo,
    Subtitulo,
    ContainerInterno,
  } = Login

  useEffect(() => {
    if (token) {
      dispatch(cursosActions.getCursosAluno())
    } else {
      dispatch(cursosActions.limparCursosAluno())
    }
  }, [token])

  useEffect(() => {
    if (token && cursosUsuario.filter((f) => f.statusDetran !== 'FINALIZADO').length === 1) {
      const curso = cursosUsuario.filter((f) => f.statusDetran !== 'FINALIZADO')[0]
      actions.newTokenMatricula({
        matriculaId: curso.matriculaId,
        callback: () => history.replace(`/curso/${curso.id}/home`),
      })
    } else if (token) {
      history.replace('/seleciona-curso')
    }
  }, [cursosUsuario])

  const formik = useFormik({
    initialValues: { usuario: '', senha: '' },
    validationSchema: Yup.object().shape({
      usuario: (Yup as any).string().required().cpf(),
      senha: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const cpf = values.usuario
      const senha = values.senha
      actions.login({ cpf, senha })
    },
  })

  return (
    <IonPage>
      <IonContent>
        <Layout>
          <Container>
            <ContainerInterno>
              <ImgMarca>
                <img src={aplicacaoPadrao} alt="" />
              </ImgMarca>
              <Titulo>Bem vindo ao EAD para condutores</Titulo>
              <Subtitulo>Preencha os campos para entrar</Subtitulo>
              <FormikProvider value={formik}>
                <Form noValidate onSubmit={formik.handleSubmit} style={{ marginTop: 28 }}>
                  <TextField
                    autoFocus
                    mask="999.999.999-99"
                    name="usuario"
                    type="tel"
                    label="Seu CPF"
                    placeholder="Informe o CPF"
                  />
                  <TextField
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') formik.handleSubmit()
                    }}
                    name="senha"
                    type="password"
                    label="Senha"
                    placeholder="Informa a senha"
                  />
                  <EsqueceuSenha>
                    <Link to="/recupera-senha">Esqueceu sua senha?</Link>
                  </EsqueceuSenha>
                  {/* <LembrarInformacoes>
                  <input type="checkbox" /> Lembrar minhas informações
                </LembrarInformacoes> */}
                  <ContainerBotao>
                    <Button label="Entrar" type="submit" />
                  </ContainerBotao>
                  {/* <NaoPossuiConta>
                    Não tem uma conta?{' '}
                    <a href="https://newdriver.com.br/#one">Clique aqui para matricular</a>
                  </NaoPossuiConta> */}
                  {/* <TermosUso>
                  Acesse os <Link to="/termos-uso">Termos de Uso</Link> e <Link to="/politica-privacidade">Política de Privacidade</Link>
                </TermosUso> */}
                </Form>
              </FormikProvider>
            </ContainerInterno>
          </Container>
        </Layout>
      </IonContent>
    </IonPage>
  )
}
Login.Layout = styled.div`
  background: #f2f2f2;
  min-height: 100vh;
  overflow: auto;
`
Login.Container = styled.div`
  max-width: 661px;
  margin: 34px auto;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  padding: 28px 0;
  @media (max-width: 689px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 12px;
  }
`
Login.ContainerInterno = styled.div`
  max-width: 400px;
  margin: 0 auto;
`
Login.ImgMarca = styled.div`
  width: 147px;
  margin: 0 auto;
  display: block;
`
Login.Titulo = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  margin-top: 90px;
`
Login.Subtitulo = styled.div`
  font-size: 18px;
  color: #000000;
  margin-top: 8px;
`
Login.EsqueceuSenha = styled.div`
  margin-top: 18px;
  font-size: 14px;
`
Login.LembrarInformacoes = styled.div`
  color: #2d2d2d;
  font-size: 14px;
  margin-top: 18px;
`
Login.NaoPossuiConta = styled.div`
  text-align: center;
  color: #2d2d2d;
  font-size: 14px;
  margin-top: 48px;
`
Login.ContainerBotao = styled.div`
  margin-top: 28px;
  button {
    margin: 0 auto;
  }
`
Login.TermosUso = styled.div`
  text-align: center;
  font-size: 12px;
  color: #a2a2a2;
  margin-top: 48px;
  a {
    color: #a2a2a2;
  }
`
